<template>
  <div>
    <!-- <a href="mailto:hello@sometimes.ooo">
      <div class="home vh-100 w-100 dt tc">
        <div class="dtc v-mid">
          <img class="" alt="SOMETIMES" src="../assets/logo.png">
        </div>
      </div>
    </a> -->
    <a href="mailto:hello@sometimes.ooo">
      <three-canvas />
    </a>
  </div>
</template>

<script>
// @ is an alias to /src
import ThreeCanvas from '@/components/ThreeCanvas.vue'

export default {
  name: 'Home',
  components: {
    ThreeCanvas
  }
};
</script>
