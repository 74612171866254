<template>
    <div id="container"></div>
</template>

<script>
import * as Three from 'three'

export default {
  name: 'ThreeCanvas',
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      logo: null
    }
  },
  methods: {
    init: function() {
        let container = document.getElementById('container');

        let fov = (container.clientWidth <= 1024) ? 100 : 70;

        this.camera = new Three.PerspectiveCamera(fov, container.clientWidth/container.clientHeight, 0.01, 10);
        this.camera.position.z = 1;

        this.scene = new Three.Scene();

        let geometry = new Three.PlaneBufferGeometry(1.5, 1.5);
        let material = new Three.MeshNormalMaterial({side: Three.DoubleSide});

        this.mesh = new Three.Mesh(geometry, material);
        this.mesh.scale.x = 12.400;
        this.mesh.scale.y = 12.400;
        this.mesh.scale.z = 12.400;
        this.mesh.position.z = -0.750;
        this.scene.add(this.mesh);

        this.makeLogo();

        // Control
        let mouseTolerance = 0.00007;
        let self = this;
        document.onmousemove = function (e) {
          var centerX = window.innerWidth * 0.5;
          var centerY = window.innerHeight * 0.5;

          self.camera.position.x = -((e.clientX - centerX) * mouseTolerance);
          self.camera.position.y = -((e.clientY - centerY) * mouseTolerance);
        };

        this.renderer = new Three.WebGLRenderer({antialias: true});
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize(container.clientWidth, container.clientHeight);
        container.appendChild(this.renderer.domElement);
    },
    makeLogo: function() {
      let texture = new Three.TextureLoader().load( require('./textures/logo_white.png') );
      let material = new Three.SpriteMaterial({map: texture});

      // material.blending = Three.MultiplyBlending;

      this.logo = new Three.Sprite(material);
// console.log(this.logo);
      // sprite.scale.set(material.map.image.width, material.map.image.height);
      this.scene.add(this.logo);
    },
    animate: function() {
        requestAnimationFrame(this.animate);
        this.mesh.rotation.x += 0.01/3;
        this.mesh.rotation.y += 0.02/3;
        this.mesh.rotation.z += 0.002/3;

        this.renderer.render(this.scene, this.camera);
    }
  },
  mounted() {
      this.init();
      this.animate();
  }
};
</script>

<style scoped>
#container {
  width: 100vw;
  height: 100vh;
}
</style>